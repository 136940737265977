import { seConstants } from '../constants';
import { getControlKeys, getControlTypes, getRequiredControls, checkCondition } from '../helpers';
import store from 'store2';
import * as _ from 'lodash';

const localData = store('swsm-se');
const isLocalDataEmpty = _.isEmpty(localData);

const initialState = isLocalDataEmpty
  ? {
    navNextVisible: false,
    modal: false,
    animateForward: true,
    fetching: false,
    fetched: false,
    error: null,
    currentStep: 0,
    totalSteps: 0,
    steps: [],
    startTime: Date.now(),
    answers: {},
    dropdownValue: {},
    conditionalKeysVisibility: {},
    summary: {
      answersStatus: {
        loading: false,
        done: false,
        error: null,
      },
    },
  }
  : localData;

const savingengineReducer = (state = initialState, action) => {
  switch (action.type) {
    case seConstants.GET_QUESTIONS + '_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null,
      };

    case seConstants.GET_QUESTIONS + '_FULFILLED':
      let payloadData = action.payload.data || [];

      if (
        action.payload.headers['content-type'] !== 'application/json' &&
        action.payload.headers['content-type'] !== 'application/json; charset=utf-8'
      ) {
        payloadData = [];
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        currentStep: 0,
        startTime: Date.now(),
        totalSteps: payloadData.length,
        answers: {},
        steps: payloadData,
        summary: {
          answersStatus: {
            loading: false,
            done: false,
            error: null,
          },
        },
      };

    case seConstants.GET_QUESTIONS + '_REJECTED':
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };

    case seConstants.GO_TO:
      return {
        ...state,
        currentStep: action.index,
      };

    case seConstants.GO_TO_NEXT:
      const nextIndex = state.currentStep + 1;
      window.scroll(0, 0);
      // Prevent setting up higher index than exist
      if (state.currentStep < state.totalSteps - 1) {
        let navNextVisible = true;
        const nextState = checkCondition(state, nextIndex, true);
        const nextStep = nextState.steps[nextState.currentStep];

        const controlTypes = getControlTypes(nextStep);
        const controlKeys = getControlKeys(nextStep);
        const requiredControls = getRequiredControls(nextStep);
        const controlsWithoutNextButton = ['buttons'];

        // console.log({requiredControls});
        // console.log({controlTypes});

        // if contain controlsWithoutNextButton
        if (
          (controlTypes && controlsWithoutNextButton.some(r => controlTypes.includes(r))) ||
          requiredControls.length > 0
        ) {
          // also disable if any of controls in step are required
          const hasAllKeys = [...controlKeys, ...requiredControls].every(item => {
            return nextState.answers.hasOwnProperty(item);
          });

          // if keys are not answered before
          if (!hasAllKeys) {
            navNextVisible = false;
          }
        }

        store('swsm-se', {
          ...nextState,
          navNextVisible,
        });

        return {
          ...nextState,
          navNextVisible,
          animateForward: true,
        };
      }
      // DO nothing next question don't exist
      return {
        ...state,
      };

    case seConstants.GO_TO_PREV:
      const prevIndex = state.currentStep - 1;
      window.scroll(0, 0);
      // Prevent setting up higher index than exist
      if (state.currentStep > 0) {
        const nextState = checkCondition(state, prevIndex, false);

        return {
          ...nextState,
          navNextVisible: true,
          animateForward: false,
        };
      }
      return {
        ...state,
      };

    case seConstants.VALIDATE_REQUIRED_KEYS:
      let navNextVisible = true;
      const requiredControls = getRequiredControls(state.steps[state.currentStep]);

      const obj = state.answers;
      const hasAllKeys = requiredControls.every(item => {
        // if its 0 as number should be considered as TRUE
        if (obj[item] === 0) {
          return true;
        }
        return obj.hasOwnProperty(item) && obj[item];
      });

      if (!hasAllKeys) {
        navNextVisible = false;
      }

      return {
        ...state,
        navNextVisible,
      };

    case seConstants.UPDATE_ANSWER_KEY:
      // console.log('UPDATE_ANSWER_KEY');

      store('swsm-se', {
        ...state,
        currentStep: state.currentStep,
        navNextVisible: state.navNextVisible,

        answers: {
          ...state.answers,
          [action.key]: action.value,
        },
      });

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.key]: action.value,
        },
      };
    case seConstants.SET_DROPDOWN_KEY:
      return {
        ...state,
        dropdownValue: {
          ...state.dropdownValue,
          [action.key]: action.value,
        },
      };

    case seConstants.SET_NAV_NEXT_VISIBILITY:
      // console.log('SET_NAV_NEXT_VISIBILITY');

      return {
        ...state,
        navNextVisible: action.visible,
      };

    case seConstants.POST_ANSWERS + '_PENDING':
      // console.log('POST_ANSWERS_PENDING');
      return {
        ...state,
        summary: {
          ...state.summary,
          answersStatus: {
            loading: true,
            done: false,
            error: null,
          },
        },
      };
    case seConstants.POST_ANSWERS + '_FULFILLED':
      // TODO: If userDataStatus us also done clear localStorage
      // if (state.summary.userDataStatus.done) {
      //   store('swsm-se', {});
      // }

      return {
        ...state,
        summary: {
          ...state.summary,
          answersStatus: {
            loading: false,
            done: true,
            error: null,
          },
        },
      };
    case seConstants.POST_ANSWERS + '_REJECTED':
      return {
        ...state,
        summary: {
          ...state.summary,
          answersStatus: {
            loading: false,
            done: false,
            error: _.get(action, 'payload.response.data.errors', 'Problem with submitting answers'),
          },
        },
      };

    case seConstants.START_TRACKING + '_FULFILLED':
      return {
        ...state,
        tracking_id: action.payload.data.tracking_id,
      };

    case seConstants.TRACKING + '_FULFILLED':
      return {
        ...state,
        tracking_id: action.payload.data.tracking_id,
      };

    case seConstants.COMPLETE_TRACKING + '_FULFILLED':
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default savingengineReducer;
