import React from 'react';
import { clearFix } from 'polished';
import styled, { css } from 'styled-components';
import { Button } from '@savewatersavemoney/swsm-ui';
import store from 'store2';
import i18next from 'i18next';
import history from '../../history';
import { colors } from '../../assets/variables';
interface StepNavProps {
  disabled?: boolean;
  initial?: boolean;
  handleNext?: (e: any) => void;
  handlePrev?: (e: any) => void;
  disablePrev?: boolean;
  disableNext?: boolean;
  modalShow: Function;
}

const StyledStepNav = styled.div`
  display: flex;
  flex-direction: row;
  width: 220px;
  margin: 30px auto 0;
  ${clearFix()}
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
    margin: 30px 0;
  }
`;
const Restart = styled.div`
  ${props => {
    if (props.isDesktop) {
      return css`
        margin-top: 16px;
      `;
    }
  }}
  text-align: center;
`;

const StyledInitial = styled(Button)`
  padding: 16px;
  text-align: center;
  flex: 1;
  background-color: ${colors.primary};
  &:hover {
    background-color: ${colors.action};
    color: ${colors.primary};
  }
`;

const StyledBack = styled(Button)`
  padding: 16px;
  text-align: center;
  flex: 1;
`;

const StyledNext = styled(Button)`
  padding: 16px;
  text-align: center;
  flex: 1;
  background: ${colors.primary};
  ${props => {
    if (props.disabled) {
      return css`
        background: #d8d8d8;
        color: #595959;
      `;
    }
  }};
`;

const resetProgress = () => {
  store('swsm-se', {});
  store('swsmUser', {});
  store.remove('swsmLocation');
  history.push('/');
};

const StepNav: React.FC<StepNavProps> = props => {
  const { initial = false } = props;

  const isDesktop = window.innerWidth > 800;
  const refreshIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 10V3L18.36 5.64C16.74 4.01 14.49 3 12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12H19C19 15.86 15.86 19 12 19C8.14 19 5 15.86 5 12C5 8.14 8.14 5 12 5C13.93 5 15.68 5.79 16.95 7.05L14 10H21Z"
        fill="#25B3EB"
      />
    </svg>
  );
  if (initial) {
    return (
      <StyledStepNav>
        {props.handleNext ? (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
            <StyledInitial
              style={{ maxHeight: '54px', minWidth: 230, marginBottom: 16 }}
              id="stepNavInitial"
              onClick={props.handleNext}
            >
              {i18next.t('questions.stepnav_start_qa_confirm')}
            </StyledInitial>
            <StyledInitial
              appearance="OUTLINED"
              id="stepNavInitial"
              colorSecondary="#fff"
              colorPrimary="#0b2641"
              style={{ maxHeight: '54px', minWidth: 230, background: 'white' }}
              onClick={() => resetProgress()}
            >
              {i18next.t('questions.stepnav_cancel_start_qa')}
            </StyledInitial>
          </div>
        ) : (
          ''
        )}
      </StyledStepNav>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledStepNav>
        {props.handlePrev ? (
          <StyledBack
            colorPrimary="#0b2641"
            id="stepNavBack"
            appearance="OUTLINED"
            disabled={props.disablePrev}
            onClick={props.handlePrev}
            style={props.handleNext ? { marginRight: '10px', minWidth: '110px' } : null}
          >
            {i18next.t('questions.stepnav_cancel_start_qa')}
          </StyledBack>
        ) : (
          ''
        )}
        {props.handleNext ? (
          <StyledNext
            style={props.handlePrev ? { minWidth: '110px' } : null}
            id="stepNavNext"
            disabled={props.disableNext}
            onClick={props.handleNext}
          >
            {i18next.t('questions.stepnav_qa_next')}
          </StyledNext>
        ) : (
          ''
        )}
      </StyledStepNav>
      <Restart isDesktop={isDesktop}>
        <div data-test="start-again" style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            aria-label="Start again"
            style={{ fontWeight: 700, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            appearance="TRANSPARENT"
            onClick={() => props.modalShow('MODAL_RESET')}
          >
            {refreshIcon} {i18next.t('questions.reset_progress_buttontext')}
          </Button>
        </div>
      </Restart>
    </div>
  );
};

export default StepNav;
