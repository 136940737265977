import React from 'react';

// import { colors } from '../../assets/variables';

import Styled from './ProgressBar.style';

const ProgressBar = props => {
  const { steps, currentStep } = props;
  if (!steps || currentStep === 0) {
    return null;
  }
  const totalQuestions = () => {
    return steps.length;
  };
  const answeredQuestions = () => {
    return currentStep + 1;
  };
  const progress = Math.round((100 * answeredQuestions()) / totalQuestions());
  return (
    <Styled.Wraper>
      {/* {steps.map((el, index) => {
        return <Styled.Bar style={{ background: index <= currentStep ? colors.action : colors.tertiary }} />;
      })} */}
      <Styled.Bar progress={progress}></Styled.Bar>
    </Styled.Wraper>
  );
};

export default ProgressBar;
