import React from 'react';
import SavingsEngine from '../../SavingsEngine';
import QAModal from '../../QAModal';
import Styled from './Qa.style';
import { getVariable, renderParameter } from '../../../helpers';
import { Loader } from '@savewatersavemoney/swsm-ui';
import ProgressBar from '../../ProgressBar';
import ErrorDisplay from '../../ErrorDisplay';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
// import store from 'store2';

const debugMode = getVariable('mode', 'debug', false);
const isDesktop = window.innerWidth > 800;

let DebugBarContainer: any = () => null;

if (debugMode || process.env.NODE_ENV !== 'production') {
  DebugBarContainer = require('../../QADebugBar').default;
}
const swsmInternal = getVariable('mode', 'swsm', false);

class QA extends React.Component<any, any> {
  state = {
    shrink: false,
    isOpen: false,
    debugPostcode: renderParameter('postcode') ? renderParameter('postcode') : '',
  };

  componentDidMount() {}

  setShrink = bool => {
    return bool;
  };

  render() {
    const { swsmLocation } = this.props;
    const isBusiness = getVariable('mode', 'business');

    if (!swsmLocation.status.done && swsmLocation.status.loading) {
      return <Loader size={3} />;
    }

    if (!swsmLocation.data) {
      return <Redirect exact from="/question" to={'/'} />;
    }

    if (swsmLocation.status.error) {
      return (
        <div>
          <ErrorDisplay description={swsmLocation.status.error || i18next.t('portal.general.error_message')}>
            <a href={window.location.origin}>Go back to homepage</a>
          </ErrorDisplay>
        </div>
      );
    }

    // if (swsmLocation.data.site_url && window.location.origin !== swsmLocation.data.site_url) {
    //   window.location.href = swsmLocation.data.site_url;
    //   return null;
    // }

    return (
      <Styled.Main
        shrink={this.state.shrink}
        isDesktop={isDesktop}
        swsmInternal={swsmInternal}
        style={{ marginTop: '68px' }}
      >
        <ProgressBar />
        <SavingsEngine waterClient={isBusiness ? 'business' : swsmLocation.data.water_client} />
        <QAModal waterClient={isBusiness ? 'business' : swsmLocation.data.water_client} />
        <DebugBarContainer
          setShrink={bool => {
            this.setShrink(bool);
          }}
        />
      </Styled.Main>
    );
  }
}

export default QA;
