import styled, { keyframes, css } from 'styled-components';
import Shared from '../../../assets/SharedStyles.style';
import { colors } from '../../../assets/variables';
import { Button } from '@savewatersavemoney/swsm-ui';

const useAnimation = keyframes`
  from {
    width: 15px;
  }

  to {
    width: 180px;
  }
`;
const savingAnimation = keyframes`
  from {
    width: 15px;
  }

  to {
    width: 90px;
  }
`;
const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Wrap = styled.div`
  text-align: center;
  max-width: 360px;
  margin: auto;
  padding: 0 16px;
  white-space: pre-line;
  font-family: 'Open sans', sans-serif;

  @media screen and (max-width: 414px) {
    text-align: left;
  }
  .GWF-description {
    font-family: 'Open sans', sans-serif;
  }
  ${Shared.clearfix};
`;

const Error = styled.div`
  color: #ffffff;
  background-color: #ec4545;
  padding: 16px;
  margin: 20px 0;
  a {
    color: #ffffff;
    text-decoration: underline !important;
  }
`;

const ErrorInline = styled.div`
  font-size: 12px;
  padding: 5px 0 0;
  color: #ad0000;
`;

const Form = styled.form`
  text-align: left;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 0 0 20px 0;
`;

const ButtonsGroup = styled.div`
  margin-top: 20px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
`;
const BoxTop = styled.div`
  background-color: #d7f1ff;
  margin: -30px -24px 20px;
  padding: 24px 16px 24px 16px;
  width: 400px;

  h3 {
    text-align: left;
    font-family: 'Open sans', sans-serif;
    color: ${colors.primary};
  }
`;
const BoxText = styled.p`
  font-size: 14px;
  font-weight: 700;
  min-width: 58px;
  line-height: 1.2;
  width: 100px;
  text-align: right;
  font-family: 'Open sans', sans-serif;
  color: ${colors.primary};
  margin-bottom: 0px;
`;

const BoxWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`;

const BoxUse = styled.div`
  width: 15px;
  height: 30px;
  background-color: #25b3eb;
  border: 3px solid #0b2641;
  border-radius: 6px;
  margin-left: 8px;
  animation: ${useAnimation} 1s linear;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
`;

const BoxSavings = styled.div`
  width: 15px;
  height: 30px;
  background-color: #98b235;
  border: 3px solid #0b2641;
  margin-left: 8px;
  border-radius: 6px;
  animation: ${savingAnimation} 1s linear;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
`;
const ButtonStyle = styled(Button)`
  background-color: ${colors.primary};
  &:hover {
    background-color: ${colors.action};
    color: ${colors.primary};
  }
`;
const Restart = styled.div`
  ${props => {
    if (props.isDesktop) {
      return css`
        margin-top: 16px;
      `;
    }
  }}
  text-align: center;
`;
export default {
  FormGroup,
  ButtonsGroup,
  Error,
  ErrorInline,
  Wrap,
  Form,
  Title,
  BoxTop,
  BoxText,
  BoxUse,
  BoxWrap,
  BoxSavings,
  ButtonStyle,
  Restart,
};
